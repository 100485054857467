<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/../app.config";
import AdminServices from "@/services/AdminServices";

import Swal from "sweetalert2";
// import Axios from "@/config/axios";
import store from "@/state/store";

import { mapGetters } from "vuex";

export default {
  page: {
    title: "Administrateurs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Administrateurs",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Administrateurs",
          active: true,
        },
      ],
      date1: null,
      date: null,
      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      config: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      searchQuery: null,
      page: 1,
      perPage: 8,
      value: "All",
      pages: [],
      customerList: [],
      fields: { email: "", password: "", role: "" },
    };
  },
  computed: {
    ...mapGetters("admins", ["admins"]),
    ...mapGetters("system", ["isAdmin", "loggedUser"]),
    displayedPosts() {
      return this.paginate(this.admins);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.email.toLowerCase().includes(search) ||
            data.role.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },

  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  async beforeRouteEnter(to, from, next) {
    store.dispatch("admins/findAll");

    next();
  },

  methods: {
    async handleCreateUser() {
      if (this.fields.password.length < 6) {
        alert("Le mot de passe doit contenir au moins 6 caractères");
        return;
      }

      store.dispatch("setGlobalLoading", true);

      await AdminServices.create(this.fields)
        .then(async () => {
          document.getElementById("closemodal").click();
          await store.dispatch("admins/findAll");
        })
        .catch((error) => {
          this.$saError(
            error?.response?.data?.message ?? "Une erreur est survenue !"
          );
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
    editdata(data) {
      let result = this.admins.findIndex((o) => o.id == data.id);
      document.getElementById("edtidfield1").value = this.admins[result].id;
      document.getElementById("edtrolefield").value = this.admins[result].role;
    },
    async updatedata() {
      document.getElementById("edtclosemodal").click();

      this.$startLoading("Modification...");

      await AdminServices.update(document.getElementById("edtidfield1").value, {
        role: document.getElementById("edtrolefield").value,
      })
        .then(() => {
          this.$saSuccess("Modifié avec succès !");
        })
        .catch((error) => {
          this.$saError(
            error?.response?.data?.message ?? "Une erreur est survenue !"
          );
        })
        .finally(() => {
          this.$stopLoading();
          store.dispatch("admins/findAll");
        });
    },

    async deletedata(admin) {
      Swal.fire({
        html: `Êtes-vous sûr de vouloir effacer <b>${admin.email}</b> ? Cette action est irréversible !`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, supprimer !",
      }).then(async (result) => {
        if (result.value) {
          this.$startLoading("Suppression...");

          await AdminServices.delete(admin.id)
            .then(() => {
              this.$saSuccess("Suppression effectuée...");
            })
            .catch((error) => {
              this.$saError(
                error?.response?.data?.message ?? "Une erreur est survenue !"
              );
              console.log(error);
            })

            .finally(() => {
              this.$stopLoading();
              store.dispatch("admins/findAll");
            });
        }
      });
    },

    setPages() {
      let numberOfPages = Math.ceil(this.admins.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(admins) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return admins.slice(from, to);
    },
  },
  mounted() {},
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="customerList">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
                <div>
                  <h5 class="card-title mb-0">Liste des admins</h5>
                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <!-- <button
                    class="btn btn-soft-danger me-1"
                    @click="deleteMultiple"
                  >
                    <i class="ri-delete-bin-2-line"></i>
                  </button> -->
                  <button
                    type="button"
                    class="btn btn-success add-btn me-1"
                    data-bs-toggle="modal"
                    id="create-btn"
                    data-bs-target="#showModal"
                  >
                    <i class="ri-add-line align-bottom me-1"></i>
                    Ajouter un admin
                  </button>
                  <!-- <button type="button" class="btn btn-info">
                    <i class="ri-file-download-line align-bottom me-1"></i>
                    Import
                  </button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom-dashed border-bottom">
            <form>
              <div class="row g-3">
                <div class="col-xl-6">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search"
                      placeholder="Rechercher..."
                      v-model="searchQuery"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table align-middle" id="customerTable">
                  <thead class="table-light text-muted">
                    <tr>
                      <th class="sort text-center" data-sort="customer_name">
                        #
                      </th>
                      <th class="sort" data-sort="email">Email</th>
                      <th class="sort text-center" data-sort="role">Role</th>
                      <th class="sort" data-sort="date">Date d'ajout</th>
                      <th class="sort" data-sort="action">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of resultQuery" :key="index">
                      <td class="id" style="display: none">
                        <a
                          href="javascript:void(0);"
                          class="fw-medium link-primary"
                          >{{ item.id }}</a
                        >
                      </td>
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="email">{{ item.email }}</td>
                      <td class="role text-center">
                        <span
                          class="badge text-uppercase"
                          :class="{
                            'badge-soft-success': item.role == 'MODERATOR',
                            'badge-soft-warning': item.role == 'ADMIN',
                          }"
                          >{{ item.role }}</span
                        >
                      </td>
                      <td class="date">
                        {{ new Date(item.created_at).toLocaleString() }}
                      </td>
                      <td>
                        <ul
                          v-if="loggedUser.id != item.id"
                          class="list-inline hstack gap-2 mb-0"
                        >
                          <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <a
                              href="#editmodal"
                              data-bs-toggle="modal"
                              class="text-primary d-inline-block edit-item-btn"
                              @click="editdata(item)"
                            >
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Remove"
                          >
                            <a
                              class="text-danger d-inline-block remove-item-btn"
                              @click="deletedata(item)"
                            >
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <h5 class="mt-2">Pardon! Aucun résultat trouvé</h5>
                    <p class="text-muted mb-0">
                      Aucun utilisateur pour votre recherche
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Precedent
                  </a>
                  <ul
                    class="pagination listjs-pagination mb-0"
                    v-if="resultQuery.length >= 8"
                  >
                    <li
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Suivant
                  </a>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="showModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Ajout d'un admin
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input
                          type="text"
                          id="idfield1"
                          class="form-control"
                          placeholder="ID"
                          readonly
                        />
                      </div>

                      <div class="mb-3">
                        <label for="email-field" class="form-label"
                          >Adresse Email</label
                        >
                        <input
                          type="email"
                          id="email"
                          class="form-control"
                          placeholder="Entrer l'email"
                          required
                          v-model="fields.email"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="email-field" class="form-label"
                          >Mot de passe</label
                        >
                        <input
                          type="password"
                          id="password"
                          class="form-control"
                          placeholder="Entrer le mot de passe"
                          required
                          v-model="fields.password"
                        />
                      </div>

                      <div>
                        <label for="status-field" class="form-label">
                          Role
                        </label>
                        <select
                          class="form-control"
                          data-trigger
                          id="statusfield"
                          v-model="fields.role"
                        >
                          <option value="MODERATOR">Modérateur</option>
                          <option value="ADMIN">Administrateur</option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          id="closemodal"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="handleCreateUser"
                        >
                          Enregistrer l'utilisateur
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="editmodal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Modifier les droits de l'utilisateur
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input
                          type="text"
                          id="edtidfield1"
                          class="form-control"
                          placeholder="ID"
                          readonly
                        />
                      </div>

                      <div>
                        <label for="role-field" class="form-label">Role</label>
                        <select
                          class="form-control"
                          data-trigger
                          id="edtrolefield"
                        >
                          <option value="ADMIN">ADMIN</option>
                          <option value="MODERATOR">MODERATOR</option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          id="edtclosemodal"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="updatedata"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
